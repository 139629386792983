/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Bio = () => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            site {
                siteMetadata {
                    author {
                        name
                        summary
                    }
                    social {
                        twitter
                    }
                }
            }
        }
    `);

    // Set these values by editing "siteMetadata" in gatsby-config.js
    const author = data.site.siteMetadata?.author;
    const social = data.site.siteMetadata?.social;

    return (
        <div className="bio">
            <StaticImage
                className="bio-avatar"
                formats={["webp"]}
                src="../images/icon.png"
                width={50}
                height={50}
                quality={95}
                alt="Profile picture"
            />
            {author?.name && (
                <p itemProp={"author"} itemScope itemType="https://schema.org/Person">
                    Written by{" "}
                    <a
                        itemProp={"url"}
                        href={`https://x.com/${social?.twitter || ``}`}
                    >
                        <strong itemProp={"name"}>{author.name}</strong>
                    </a>{" "}
                    {author?.summary || null}.{` `}
                </p>
            )}
            <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                <meta itemProp="name" content="Real Smart Home" />
                <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                    <meta itemProp="url" content="https://realsmarthome.io/logo.png" />
                </div>
                <meta itemProp="url" content="https://realsmarthome.io" />
            </div>
        </div>
    );
};

export default Bio;
