export const blogPosts = {
    convert: (block: any, postType?: string, category?: string) => {
        return (
            (block.nodes || []).map((node: any) => {
                return {
                    id: node.id,
                    date: node.frontmatter.date,
                    title: node.frontmatter.title,
                    slug: node.fields.slug,
                    featuredImage: node.frontmatter.featuredImage,
                    type: node.frontmatter.type,
                    category: node.frontmatter.category,
                };
            }) ?? []
        ).filter((node: any) => !postType || node.type === postType)
            .filter((node: any) => !category || node.category === category);
    },
};
