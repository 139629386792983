import * as React from "react";
import { useMemo } from "react";
import { graphql, HeadFC, PageProps } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Articles } from "../components/articles";
import { blogPosts } from "../utils/data";
import { Col, Container, Row } from "react-bootstrap";
import Bio from "../components/bio";
import moment from "moment";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage: React.FC<PageProps> = ({ data }) => {
    console.log(data);
    // @ts-ignore
    const articles = useMemo(
        () => blogPosts.convert(data.allMarkdownRemark, "blog").slice(0, 8),
        [data],
    );
    // @ts-ignore
    const products = useMemo(
        () => blogPosts.convert(data.allMarkdownRemark, "product").slice(0, 8),
        [data],
    );

    return (
        <Layout activeKey={"/"}>
            <div itemScope itemType="https://schema.org/Article">
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h1 itemProp="headline">
                                    Real Smart Home - Your Gateway to Modern Living
                                </h1>
                                <StaticImage
                                    src={"../images/getty-images-CmC71C3cX8Q-unsplash.jpg"}
                                    alt={"smart home"}
                                    loading={"eager"}
                                    itemProp={"image"}
                                />
                                <meta itemProp={"datePublished"} content={moment().format()} />
                                <p>
                                    Welcome to Real Smart Home, your ultimate gateway to a world of
                                    smart home technology. Whether you're a tech enthusiast or a
                                    homeowner looking to integrate the latest innovations, we
                                    provide the resources, guidance, and solutions to help you
                                    create a connected, secure, and energy-efficient home. As the
                                    future of home automation continues to evolve, it's crucial to
                                    stay informed about cutting-edge technologies like voice
                                    control, smart security, and other transformative systems. Our
                                    mission is to ensure that you have everything you need to
                                    harness these powerful tools and create a living space that not
                                    only responds to your needs but also elevates your everyday
                                    experiences. Real Smart Home is where innovation meets comfort,
                                    offering you the best of modern living.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <h2>Explore Our Blog</h2>
                                <h3>Your Gateway to Smart Home Insights</h3>
                                <p>
                                    At Real Smart Home, we are dedicated to providing you with a
                                    comprehensive knowledge base that covers all aspects of smart
                                    home technology. Our blog is more than just a collection of
                                    articles—it's a vital resource filled with actionable insights,
                                    practical tips, and expert advice designed to help you make
                                    informed decisions. Whether you're a newcomer to home automation
                                    or a seasoned pro, our content is tailored to suit your level of
                                    expertise. We delve into a wide range of topics, from the
                                    fundamentals of home automation to the latest developments and
                                    trends, helping you stay ahead in the rapidly evolving tech
                                    landscape. Our goal is to empower you to create a smarter, more
                                    efficient home that enhances both comfort and functionality.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div>
                                <h2>Latest Smart Home News</h2>
                                <p>
                                    The world of technology moves quickly, and staying informed is
                                    key to ensuring that you're always ahead of the curve. That's
                                    why we’ve dedicated a section of our website to the latest in{" "}
                                    smart home news. Here, you'll find timely updates on new product
                                    releases, software updates, and industry innovations that are
                                    shaping the future of smart homes. Our news section covers
                                    everything from breakthrough devices that enhance your
                                    day-to-day life to larger trends that are set to redefine the
                                    way we live in our homes. With our up-to-date coverage, you’ll
                                    always be informed about the advancements that matter most,
                                    allowing you to make proactive decisions about your home
                                    technology.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Articles articles={articles} />

                <Container>
                    <Row>
                        <Col sm={6} md={4}>
                            <div>
                                <h2>Discover the Latest in Smart Home Technology</h2>
                                <p>
                                    As smart home technology continues to evolve, keeping up with
                                    the latest innovations is essential. At Real Smart Home, we aim
                                    to bring you the most current and relevant information on the
                                    latest devices and advancements. From Google Home to the newest
                                    Google Nest systems, we cover the products that are transforming
                                    homes across the globe. Whether you’re interested in integrating
                                    these devices into your existing setup or starting fresh with an
                                    entirely new smart home system, our resources provide the
                                    guidance you need to make informed choices. With detailed
                                    insights into the benefits of each product, you'll be
                                    well-equipped to enhance your home and make it more intelligent,
                                    efficient, and responsive to your needs.
                                </p>
                            </div>
                        </Col>

                        <Col sm={6} md={4}>
                            <div>
                                <h2>Master Voice Control and Automation</h2>
                                <p>
                                    Voice control has revolutionized the way we interact with
                                    technology, offering unparalleled convenience and functionality.
                                    With devices like Google Home, you can manage your entire home
                                    through simple voice commands, from adjusting the temperature to
                                    controlling your lighting and security systems. Our blog
                                    provides step-by-step guides on how to set up and optimize your{" "}
                                    voice-activated devices, allowing you to create a fully
                                    responsive and integrated home automation system. Whether you're
                                    new to voice control or looking to fine-tune your setup, our
                                    comprehensive resources will help you master the technology and
                                    maximize the efficiency of your smart home.
                                </p>
                            </div>
                        </Col>

                        <Col sm={6} md={4}>
                            <div>
                                <h2>Enhance Your Home Security</h2>
                                <p>
                                    Security is a top priority for every homeowner, and with smart
                                    technology, protecting your home has never been easier or more
                                    effective. Smart locks, advanced security systems, and remotely
                                    controlled door locks allow you to safeguard your home from
                                    anywhere in the world, providing peace of mind whether you're at
                                    home or away. On our blog, we explore the best security products
                                    on the market, offering in-depth reviews and setup guides to
                                    help you implement a reliable and comprehensive security system.
                                    Protecting your home doesn’t have to be complicated—let us show
                                    you how easy it is to enhance your home security with the latest
                                    in smart technology.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div>
                                <h2>Transform Your Home with Smart Home Products</h2>
                                <p>
                                    The smart home market is rapidly growing, with new and
                                    innovative products being released all the time. In our product
                                    section, we feature a curated selection of the most advanced
                                    smart home devices available today, making it easy for you to
                                    find exactly what you need to enhance your living space. Whether
                                    you're upgrading your smart lighting system, installing a smart
                                    thermostat to control your home's climate, or exploring
                                    all-in-one solutions for full home automation, we have the tools
                                    to help you succeed. Our expert recommendations and detailed
                                    product reviews ensure that you're making informed decisions
                                    when selecting the best products to transform your home into a
                                    modern, connected environment.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Articles articles={products} />

                <Container>
                    <Row>
                        <Col sm={6}>
                            <div>
                                <h2>Why Choose Real Smart Home?</h2>
                                <p>
                                    At Real Smart Home, we pride ourselves on offering more than
                                    just products—we offer a comprehensive experience that combines
                                    expert guidance with a carefully curated selection of top-tier
                                    devices. Our deep expertise in smart home technology, combined
                                    with our commitment to staying ahead of the curve, ensures that
                                    you have access to the latest and most reliable products on the
                                    market. Whether you're just beginning your journey into home
                                    automation or looking to enhance your existing setup, we’re here
                                    to support you every step of the way. Trust Real Smart Home as
                                    your ultimate resource for creating a smarter, more connected
                                    living space that caters to your needs.
                                </p>
                                <ul>
                                    <li>Extensive expertise in smart home technology</li>
                                    <li>Handpicked selection of top-tier products</li>
                                    <li>Guidance for setting up full home automation systems</li>
                                    <li>Support for integrating smart devices into your home</li>
                                </ul>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div>
                                <h2>Start Your Smart Home Journey Today</h2>
                                <p>
                                    The time to start your smart home journey is now, and Real Smart
                                    Home is here to guide you every step of the way. Whether you're
                                    just beginning to explore home automation or are looking to
                                    expand your current system, we provide the knowledge and
                                    resources you need to create a more intelligent, secure, and
                                    efficient living environment. Our blog offers in-depth articles
                                    on a variety of topics, and our product section features the
                                    latest and greatest in smart home technology. Take the first
                                    step today, and let us help you turn your home into a connected,
                                    intelligent space that meets all your needs.
                                </p>
                                <p>
                                    Thank you for choosing Real Smart Home as your trusted partner
                                    in this exciting journey. Together, we can help you create a
                                    home that is not only smarter and more secure but also perfectly
                                    tailored to your unique lifestyle and preferences.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <footer>
                        <Bio />
                    </footer>
                </Container>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query IndexPage {
        allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;

export const Head: HeadFC = () => (
    <Seo
        url={"/"}
        title="Real Smart Home - news from home automation world"
        shortTitle
        description={
            "Explore smart home automation trends, tips, and reviews to enhance your living space with improved comfort, security, and efficiency."
        }
    />
);
